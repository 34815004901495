import FuseScrollbars from "@fuse/core/FuseScrollbars";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import withReducer from "app/store/withReducer";
import { useEffect, memo } from "react";
import { styled } from "@mui/material/styles";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import Button from "@mui/material/Button";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleNotificationPanel,
  selectNotificationPanelState,
  closeNotificationPanel,
} from "app/theme-layouts/shared-components/notificationPanel/store/stateSlice";
import reducer from "app/theme-layouts/shared-components/notificationPanel/store";
import { FormControlLabel, RadioGroup } from "@mui/material";
import Switch from "@mui/material/Switch";
import FuseLoading from "@fuse/core/FuseLoading/FuseLoading";

import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import { actions } from "react-table";
import jwtService from "../../auth/services/jwtService";
import { resetNavigation } from "app/store/fuse/navigationSlice";

const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    backgroundColor: "white",
    width: "40%",
  },
}));

function ActionsTestDrawer() {
  const dispatch = useDispatch();
  const state = useSelector(selectNotificationPanelState);
  const dvLinkRoute = "https://displayvideo.google.com/ng_nav/p/675447/";
  const [advertiserRoute, setAdvertiserRoute] = useState();
  const [campaignRoute, setCampaignRoute] = useState();
  const [insertionOrderRoute, setInsertionOrderRoute] = useState();
  const [lineItemRoute, setLineItemRoute] = useState();

  const [loading, setLoading] = useState(false);
  const [listType, setListType] = useState(
      state.item.actionType
  );
  const [actionsData, setActionsData] = useState();
  const [radioValue, setRadioValue] = useState(0);
  const [publishers, setPublishers] = useState([]);

  function handleClose() {
    dispatch(closeNotificationPanel());
  }

  const getActionsData = async () => {
    try {
      setLoading(true);
      const response = await fetch(
          `actions.json`,
          { headers: { Authorization: `Bearer ${jwtService.getAccessToken()}` } }
      );
      const data = await response.json();
      console.log('data[0]',data[0]);
      setActionsData(data[0]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const sendActionsData = async () => {
    try {
      setLoading(true);
      /*const response = await fetch(
          `${process.env.REACT_APP_API_URL}/Actions/`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtService.getAccessToken()}`,
            },
            method: "PUT",
            body: JSON.stringify({
              actionId: actionsData.actionId,
              actionType: listType,
              status: Number(radioValue),
            }),
          }
      );
      await response.json();*/
      dispatch(resetNavigation()); // Çalışmıyor. Navigation güncellenmeli.
      //handleClose();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePublishers = () => {
    /*    setPublishers(
           state?.item?.actionTypesGroupped?.publishers
        );*/

  };

  useEffect(() => {
    if (state?.item?.actionId !== undefined) {
      getActionsData();
    }
  }, [state?.item?.actionId]);

  useEffect(() => {
    handleChangePublishers();
  }, [listType, actionsData?.actionId]);

  useEffect(() => {
    if (actionsData?.status !== undefined) {
      setRadioValue(actionsData?.status);
    }
  }, [actionsData?.status]);

  return (
      <StyledSwipeableDrawer
          open={state?.toggle || false}
          anchor="right"
          onOpen={(ev) => {}}
          onClose={handleClose}
          disableSwipeToOpen
      >
        <IconButton
            className="m-4 absolute top-0 right-0 z-999"
            onClick={handleClose}
            size="large"
        >
          <FuseSvgIcon color="action">heroicons-outline:x</FuseSvgIcon>
        </IconButton>

        {loading ? (
            <FuseLoading />
        ) : (
            <FuseScrollbars className="p-16">
              <div className="flex flex-col">
                <div className="flex justify-between items-end pt-60 mb-40 pl-8">
                  <Typography className="text-3xl font-semibold leading-none">
                    #{actionsData?.actionId} Öneri Detayları
                  </Typography>
                </div>
                <div className="flex flex-col">
                  <div className="grid grid-cols-2">
                    <Typography
                        className="text-xl font-semibold leading-none p-10"
                        variant="h6"
                    >
                      Reklamveren
                      <a
                          href={dvLinkRoute + `a/${actionsData?.advertiserId}/cs`}
                          target="_blank"
                      >
                        <Typography className="mt-8 relative max-w-max break-all">
                          {actionsData?.advertiserName}
                          {/* <LaunchIcon className="text-md absolute -top-3 -right-14" /> */}
                        </Typography>
                      </a>
                    </Typography>
                    <Typography
                        className="text-xl font-semibold leading-none p-2"
                        variant="h6"
                    >
                      Kampanya
                      <a
                          href={
                              dvLinkRoute +
                              `a/${actionsData?.advertiserId}/c/${actionsData?.campaignId}/explorer`
                          }
                          target="_blank"
                      >
                        <Typography className="mt-8 relative max-w-max break-all">
                          {actionsData?.campaignName}
                          {/* <LaunchIcon className="text-md absolute -top-3 -right-14" /> */}
                        </Typography>
                      </a>
                    </Typography>
                    <Typography
                        className="text-xl font-semibold leading-none p-2"
                        variant="h6"
                    >

                      <Typography className="leading-none mt-8">

                      </Typography>
                    </Typography>

                  </div>
                </div>

                {actionsData?.actionTypes?.map((i) => (
                    <div className="flex flex-col border-t-2 mt-5 pt-10">
                      <div className="grid grid-cols-2 ">
                        <Typography
                            className="text-xl font-semibold leading-none p-10"
                            variant="h6"
                        >
                          Öneri Seviyesi
                          <Typography className="leading-none  mt-8">
                            {i.level}
                          </Typography>
                        </Typography>
                        <Typography
                            className="text-xl font-semibold leading-none p-10"
                            variant="h6"
                        >
                          Öneri Seviyesi Adı
                          <Typography className="leading-none  mt-8">
                            {i.level=="Ad Group" ? actionsData.insertionOrderName : actionsData.lineItemName}
                          </Typography>
                        </Typography>
                        <Typography
                            className="text-xl font-semibold leading-none p-10"
                            variant="h6"
                        >
                          Öneri Tipi
                          <Typography className="leading-none  mt-8">
                            {i.actionType}
                          </Typography>
                        </Typography>
                        <Typography
                            className="text-xl font-semibold leading-none p-10"
                            variant="h6"
                        >
                          Öneri Sebebi
                          <Typography className="leading-none  mt-8">
                            {i.actionDescription}
                          </Typography>
                        </Typography>

                      </div>
                      <div className="grid grid-cols-2 gap-x-20 mt-28">
                        <TextField
                            className=" mb-16 col-span-2 h-150"
                            id="desc"
                            label={`Yayıncılar (${
                                i.dataCount
                            }) 
                      `}
                            type="text"
                            multiline
                            value={
                              i.publishers?.length > 0
                                  ? i.publishers?.join("\n")
                                  : "Yayıncı bulunamadı."
                            }
                            rows={4}
                            variant="outlined"
                            fullWidth
                        />
                      </div>
                      <div className="flex items-center gap-5">
                        <FormControl className="ml-10">
                          <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={radioValue}
                              onChange={(e) => setRadioValue(e.target.value)}
                          >
                            <FormControlLabel
                                value={0}
                                control={<Radio className="p-0 m-0 select-none"/>}
                                label="Yeni"
                                className="py-3 px-10 pl-3 rounded-full  select-none"
                            />
                            <FormControlLabel
                                value={1}
                                control={<Radio className="p-0 m-0 select-none"/>}
                                label="Uygulandı"
                                className="py-3 px-10 pl-3 rounded-full  select-none"
                            />
                            <FormControlLabel
                                value={2}
                                control={<Radio className="p-0 m-0 select-none"/>}
                                label="Planlandı"
                                className="py-3 px-10 pl-3 rounded-full  select-none"
                            />
                            <FormControlLabel
                                value={3}
                                control={<Radio className="p-0 m-0 select-none"/>}
                                label="Yoksay"
                                className="py-3 px-10 pl-3 rounded-full  select-none"
                            />
                            <Button
                                variant="contained"
                                color="success"
                                className="max-w-max -ml-10 mt-5 "
                                onClick={sendActionsData}
                                // disabled={!publishers?.length > 0}
                            >
                              Kaydet
                            </Button>
                          </RadioGroup>

                        </FormControl>
                      </div>
                    </div>
                ))}
              </div>
            </FuseScrollbars>
        )}
      </StyledSwipeableDrawer>
  );
}

export default withReducer("ActionsDrawer", reducer)(memo(ActionsTestDrawer));
