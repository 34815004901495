import clsx from "clsx";

function FusePageCardedHeader(props) {
  return (
    <div className={clsx("FusePageCarded-header", "container !max-w-[93%]")}>
      {props.header && props.header}
    </div>
  );
}

export default FusePageCardedHeader;
