import "moment/locale/tr";


const ResponseMessage = ({ status,show }) => {


    return show ? (
        status ? (
            <>
                <div className="flex item-center mr-5 ml-5" style={{alignItems: 'center',color:'green'}} >
                    Operasyon Başarılı
                </div>
            </>
        ) :  (
            <>
                <div className="flex item-center mr-5 ml-5" style={{alignItems: 'center',color:'red'}}>
                    Operasyon Başarısız
                </div>
            </>
        )
    ) : '';
};

export default ResponseMessage;
