export const labelInsertionOrderMapping = {
    DV360: "Insertion Order",
    GoogleAds: "Ad Group",
    MetaAds: "Ad Group",
    Analytics: "Ad Group",
};

export const insertionOrderTypeOptions = [
    "CPM",
    "CPC",
    "CPA",
    //"CPIAVC",
    //"VCPM",
    "CTR",
    //"VIEWABILITY",
    //"Click CVR",
    //"Impression CVR",
    "VTR",
    //"Audio CR",
    "Video CR",
    "CPV (Cost Per View)",
    //"eCPC",
    //"eCPA",
    "ROAS",
];
export const insertionOrderTypeOptionsTranslate = {
    PERFORMANCE_GOAL_TYPE_CPM: "CPM",
    PERFORMANCE_GOAL_TYPE_CPC: "CPC",
    PERFORMANCE_GOAL_TYPE_CPA: "CPA",
    //PERFORMANCE_GOAL_TYPE_CPIAVC: "CPIAVC",
    //PERFORMANCE_GOAL_TYPE_VCPM: "VCPM",
    PERFORMANCE_GOAL_TYPE_CTR: "CTR",
    //PERFORMANCE_GOAL_TYPE_VIEWABILITY: "VIEWABILITY",
    //PERFORMANCE_GOAL_TYPE_CLICK_CVR: "Click CVR",
    //PERFORMANCE_GOAL_TYPE_IMPRESSION_CVR: "Impression CVR",
    PERFORMANCE_GOAL_TYPE_VTR: "VTR",
    //PERFORMANCE_GOAL_TYPE_AUDIO_COMPLETION_RATE: "Audio CR",
    PERFORMANCE_GOAL_TYPE_VIDEO_COMPLETION_RATE: "Video CR",
    PERFORMANCE_GOAL_TYPE_CPV: "CPV (Cost Per View)",
    //PERFORMANCE_GOAL_TYPE_ECPC: "eCPC",
    //PERFORMANCE_GOAL_TYPE_ECPA: "eCPA",
    PERFORMANCE_GOAL_TYPE_ROAS: "ROAS",
};

export const statusAndCustomPerformanceGoalType = {
    Status: {
        0: "Yeni Öneri",
        1: "Uygulanmış Öneri",
        2: "Planlanan Öneri",
        3: "Yoksayılan Öneri",
    },
    customPerformanceGoalType: {
        PERFORMANCE_GOAL_TYPE_CPM: "CPM",
        PERFORMANCE_GOAL_TYPE_CPC: "CPC",
        PERFORMANCE_GOAL_TYPE_CPA: "CPA",
        //PERFORMANCE_GOAL_TYPE_CPIAVC: "CPIAVC",
        //PERFORMANCE_GOAL_TYPE_VCPM: "VCPM",
        PERFORMANCE_GOAL_TYPE_CTR: "CTR",
        //PERFORMANCE_GOAL_TYPE_VIEWABILITY: "VIEWABILITY",
        //PERFORMANCE_GOAL_TYPE_CLICK_CVR: "Click CVR",
        //PERFORMANCE_GOAL_TYPE_IMPRESSION_CVR: "Impression CVR",
        PERFORMANCE_GOAL_TYPE_VTR: "VTR",
        //PERFORMANCE_GOAL_TYPE_AUDIO_COMPLETION_RATE: "Audio CR",
        PERFORMANCE_GOAL_TYPE_VIDEO_COMPLETION_RATE: "Video CR",
        PERFORMANCE_GOAL_TYPE_CPV: "CPV (Cost Per View)",
        //PERFORMANCE_GOAL_TYPE_ECPC: "eCPC",
        //PERFORMANCE_GOAL_TYPE_ECPA: "eCPA",
        PERFORMANCE_GOAL_TYPE_ROAS: "ROAS",
    },
};
