import { lazy } from 'react';

const ActivitiesPage2 = lazy(() => import('./ActivitiesPage2'));

const activitiesPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'pages/activities',
      element: <ActivitiesPage2 />,
    },
  ],
};

export default activitiesPageConfig;
