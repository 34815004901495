import FuseScrollbars from "@fuse/core/FuseScrollbars";
import Typography from "@mui/material/Typography";
import withReducer from "app/store/withReducer";
import { useSnackbar } from "notistack";
import { memo, useEffect } from "react";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import reducer from "../../theme-layouts/shared-components/notificationPanel/store";
import { selectNotificationPanelState } from "../../theme-layouts/shared-components/notificationPanel/store/stateSlice";
import moment from "moment";
import FuseLoading from "@fuse/core/FuseLoading/FuseLoading";
import jwtService from "../../auth/services/jwtService";
import { Divider } from "@mui/material";
import ResponseMessage from "../Components/ResponseMessage";

function LineItemDrawer(props) {
  const state = useSelector(selectNotificationPanelState);
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // Line Items Start
  const [lineItemData, setLineItemData] = useState();
  const [responseStatus, setResponseStatus] = useState("");
  const [responseShow, setResponseShow] = useState(0);
  const getLineItemData = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/LineItems/${state?.item?.lineItemId}`,
        { headers: { Authorization: `Bearer ${jwtService.getAccessToken()}` } }
      );
      const data = await response.json();
      await getIngageTagsOptions();
      await getCategoryOptions();
      setLineItemData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeData = (e, newValue, name) => {
    const updatedData = name
      ? { ...lineItemData, [name]: newValue }
      : { ...lineItemData, [e.target.name]: e.target.value };
    setLineItemData(updatedData);
  };

  const sendLineItemData = async () => {
    setResponseShow(false);
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/LineItems/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtService.getAccessToken()}`,
          },
          method: "PUT",
          body: JSON.stringify({
            ...lineItemData,
            tagIds: [1],
          }),
        }
      );
      const data = await response.json();
      setResponseShow(true);
      setResponseStatus(true);
    } catch (error) {
      console.log(error);
      setResponseShow(true);
      setResponseStatus(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLineItemData();
  }, [state?.item?.lineItemId]);
  // Line Items End

  const [ingageTagsOptions, setIngageTagsOptions] = useState([]);
  const getIngageTagsOptions = async () => {
    try {
      setLoading(true);
      const response = await fetch(
          `${process.env.REACT_APP_API_URL}/IngageTags?pageIndex=1&pageSize=1000`,
        { headers: { Authorization: `Bearer ${jwtService.getAccessToken()}` } }
      );
      const data = await response.json();
      setIngageTagsOptions(data.items);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const [categoryOptions, setCategoryOptions] = useState([]);
  const getCategoryOptions = async () => {
    try {
      setLoading(true);
      const response = await fetch(
          `${process.env.REACT_APP_API_URL}/IngageCategories?pageIndex=1&pageSize=1000`,
        { headers: { Authorization: `Bearer ${jwtService.getAccessToken()}` } }
      );
      const data = await response.json();
      setCategoryOptions(data.items);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   getIngageTagsOptions();
  //   getCategoryOptions();
  // }, [state]);

  return (
    <div>
      {loading ? (
        <FuseLoading />
      ) : (
        <div>
          <FuseScrollbars className="p-16">
            <div className="flex flex-col">
              <div className="flex justify-between items-end pt-60 mb-40 pl-8">
                <Typography className="text-3xl font-semibold leading-none">
                  Line Item Detayları
                </Typography>
              </div>
              <div className="flex flex-col">
                <div className="grid grid-cols-2">
                  <Typography
                    className="text-xl font-semibold leading-none p-10"
                    variant="h6"
                  >
                    Line Item ID: <br />
                    <Typography className="leading-none mt-8">
                      {lineItemData?.lineItemId}
                    </Typography>
                  </Typography>
                  <Typography
                    className="text-xl font-semibold leading-none p-10"
                    variant="h6"
                  >
                    Line Item Adı
                    <Typography className="leading-none mt-8">
                      {lineItemData?.displayName}
                    </Typography>
                  </Typography>

                  <Typography
                    className="text-xl font-semibold leading-none p-10"
                    variant="h6"
                  >
                    Line Item Tipi
                    <Typography className="leading-none mt-8 truncate">
                      {lineItemData?.lineItemType}
                    </Typography>
                  </Typography>
                  <Typography
                    className="text-xl font-semibold leading-none p-10"
                    variant="h6"
                  >
                    Line Item Durumu
                    <Typography className="leading-none  mt-8">
                      {lineItemData?.entityStatus}
                    </Typography>
                  </Typography>
                  <Typography
                    className="text-xl font-semibold leading-none p-10"
                    variant="h6"
                  >
                    Line Item Başlangıç Tarihi
                    <Typography className="leading-none  mt-8">
                      {moment(lineItemData?.startDate).format("DD.MM.YYYY")}
                    </Typography>
                  </Typography>
                  <Typography
                    className="text-xl font-semibold leading-none p-10"
                    variant="h6"
                  >
                    Line Item Bitiş Tarihi
                    <Typography className="leading-none  mt-8">
                      {moment(lineItemData?.endDate).format("DD.MM.YYYY")}
                    </Typography>
                  </Typography>
                  <Typography
                    className="text-xl font-semibold leading-none p-10"
                    variant="h6"
                  >
                    Teklif Stratejisi
                    <Typography className="leading-none  mt-8">
                      {lineItemData?.bidStrategy || "-"}
                    </Typography>
                  </Typography>
                  <Typography
                    className="text-xl font-semibold leading-none p-10"
                    variant="h6"
                  >
                    Harcama Temposu
                    {/* todo : burası düzenlenecek */}
                    <Typography className="leading-none  mt-8">
                      {lineItemData?.pacingType || "-"}
                    </Typography>
                  </Typography>
                  <Typography
                    className="text-xl font-semibold leading-none p-10"
                    variant="h6"
                  >
                    Frekans Sınırı
                    <Typography className="leading-none  mt-8">
                      {lineItemData?.frequencyCap || "-"}
                    </Typography>
                  </Typography>
                  <Typography
                    className="text-xl font-semibold leading-none p-10"
                    variant="h6"
                  >
                    İkaz Mesajı
                    <Typography className="mt-8 break-all">
                      {lineItemData?.warningMessages || "-"}
                    </Typography>
                  </Typography>
                </div>
                <Divider className="w-full my-24 border-1" dark />

                <div className="grid grid-cols-2 gap-x-20">
                  <TextField
                    className="mt-8 mb-16"
                    name="advertiserCostType"
                    required
                    label="Reklamveren Maliyet Tipi"
                    autoFocus
                    id="name"
                    variant="outlined"
                    fullWidth
                    value={lineItemData?.advertiserCostType}
                    onChange={handleChangeData}
                  />
                  <TextField
                    className="mt-8 mb-16"
                    name="advertiserCostAmountMicros"
                    required
                    label="Reklamveren Maliyeti – Metrik"
                    type="number"
                    id="name"
                    variant="outlined"
                    fullWidth
                    value={lineItemData?.advertiserCostAmountMicros}
                    onChange={handleChangeData}
                  />
                  <TextField
                    className="mt-8 mb-16"
                    name="advertiserCostPercentageMicros"
                    required
                    label="Reklamveren Maliyeti – Yüzde"
                    type="number"
                    id="name"
                    variant="outlined"
                    fullWidth
                    value={lineItemData?.advertiserCostPercentageMicros}
                    onChange={handleChangeData}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <ResponseMessage status={responseStatus} show={responseShow}></ResponseMessage>

              <Button
                variant="contained"
                color="primary"
                className="mt-8 mb-16"
                onClick={sendLineItemData}
              >
                Kaydet
              </Button>
            </div>
          </FuseScrollbars>
        </div>
      )}
    </div>
  );
}

export default withReducer("lineItemDrawer", reducer)(memo(LineItemDrawer));
