import FuseScrollbars from "@fuse/core/FuseScrollbars";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import withReducer from "app/store/withReducer";
import { useEffect, memo } from "react";
import { styled } from "@mui/material/styles";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import Button from "@mui/material/Button";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleNotificationPanel,
  selectNotificationPanelState,
} from "app/theme-layouts/shared-components/notificationPanel/store/stateSlice";
import reducer from "app/theme-layouts/shared-components/notificationPanel/store";
import FuseLoading from "@fuse/core/FuseLoading/FuseLoading";

import FormControl from "@mui/material/FormControl";
import jwtService from "../../auth/services/jwtService";
import ResponseMessage from "../Components/ResponseMessage";

const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    backgroundColor: "white",
    width: "40%",
  },
}));

function CategoryDrawer() {
  const dispatch = useDispatch();
  const state = useSelector(selectNotificationPanelState);

  const [loading, setLoading] = useState(false);

  function handleClose() {
    dispatch(toggleNotificationPanel());
  }

  const [categoryName, setCategoryName] = useState("");
  const [categoryDescription, setCategoryDescription] = useState("");
  const [categoryId, setCategoryId] = useState(0);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseShow, setResponseShow] = useState(0);
  const sendNewCategory = async (e) => {
    setResponseShow(false);
    e.preventDefault();

    const response = await fetch(
        `${process.env.REACT_APP_API_URL}/IngageCategories`,
      {
        method: categoryId > 0 ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtService.getAccessToken()}`,
        },
        body: JSON.stringify({
          categoryId,
          name: categoryName,
          description: categoryDescription,
        }),
      }
    );
    setResponseShow(true);
    setResponseStatus(true);
    const data = await response.json();
    handleClose();
    await state.item.getCategories();
    setCategoryName("");
    setCategoryDescription("");
    setResponseShow(false);

  };

  useEffect(() => {
    setCategoryName(state?.item?.category?.name ?? "");
    setCategoryDescription(state?.item?.category?.description ?? "");
    setCategoryId(state?.item?.category?.categoryId ?? 0);
  }, [state]);

  return (
    <StyledSwipeableDrawer
      open={state?.toggle || false}
      anchor="right"
      onOpen={(ev) => {}}
      onClose={handleClose}
      disableSwipeToOpen
    >
      <IconButton
        className="m-4 absolute top-0 right-0 z-999"
        onClick={handleClose}
        size="large"
      >
        <FuseSvgIcon color="action">heroicons-outline:x</FuseSvgIcon>
      </IconButton>

      {loading ? (
        <FuseLoading />
      ) : (
        <FuseScrollbars className="p-16">
          <div className="flex flex-col">
            <div className="flex justify-between items-end pt-60 mb-40 pl-8">
              <Typography className="text-3xl font-semibold leading-none">
                {categoryId ? "Kategoriyi Düzenle" : "Yeni Kategori Ekle"}
              </Typography>
            </div>
            <div className="flex flex-col">
              <form className="mt-24" onSubmit={(e) => sendNewCategory(e)}>
                <FormControl className="w-full">
                  <TextField
                    required
                    type="text"
                    variant="outlined"
                    label="Kategori Adı"
                    name="name"
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                  />
                  <TextField
                    multiline
                    rows={4}
                    type="text"
                    variant="outlined"
                    label="Açıklama"
                    className="my-10"
                    value={categoryDescription}
                    onChange={(e) => setCategoryDescription(e.target.value)}
                  />
                  <Button type="submit" variant="contained" color="primary">
                    Kaydet
                  </Button>
                  <ResponseMessage status={responseStatus} show={responseShow}></ResponseMessage>

                </FormControl>
              </form>
            </div>
          </div>
        </FuseScrollbars>
      )}
    </StyledSwipeableDrawer>
  );
}

export default withReducer("CategoryDrawer", reducer)(memo(CategoryDrawer));
