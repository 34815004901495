import FuseScrollbars from "@fuse/core/FuseScrollbars";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import withReducer from "app/store/withReducer";
import { useEffect, memo, useState } from "react";
import { styled } from "@mui/material/styles";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import Button from "@mui/material/Button";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleNotificationPanel,
  selectNotificationPanelState,
} from "app/theme-layouts/shared-components/notificationPanel/store/stateSlice";
import reducer from "app/theme-layouts/shared-components/notificationPanel/store";
import FuseLoading from "@fuse/core/FuseLoading/FuseLoading";

import FormControl from "@mui/material/FormControl";
import jwtService from "../../auth/services/jwtService";
import ResponseMessage from "../Components/ResponseMessage";

const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    backgroundColor: "white",
    width: "40%",
  },
}));

function TagDrawer() {
  const dispatch = useDispatch();
  const state = useSelector(selectNotificationPanelState);

  const [loading, setLoading] = useState(false);

  function handleClose() {
    dispatch(toggleNotificationPanel());
  }

  const [tagId, setTagId] = useState(0);
  const [tagName, setTagName] = useState("");
  const [tagDescription, setTagDescription] = useState("");
  const [responseStatus, setResponseStatus] = useState("");
  const [responseShow, setResponseShow] = useState(0);
  const sendNewTag = async (e) => {
    e.preventDefault();
    setResponseShow(false);
    const response = await fetch(
        `${process.env.REACT_APP_API_URL}/IngageTags`,
      {
        method: tagId > 0 ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtService.getAccessToken()}`,
        },
        body: JSON.stringify({
          tagId,
          name: tagName,
          description: tagDescription,
        }),
      }
    );
    const data = await response.json();
    setResponseShow(true);
    setResponseStatus(true);
    handleClose();
    await state?.item?.getTags();
    setTagName("");
    setTagDescription("");
    setResponseShow(false);


  };

  useEffect(() => {
    setTagId(state?.item?.tag?.tagId ?? 0);
    setTagName(state?.item?.tag?.name ?? "");
    setTagDescription(state?.item?.tag?.description ?? "");
  }, [state]);

  return (
    <StyledSwipeableDrawer
      open={state?.toggle || false}
      anchor="right"
      onOpen={(ev) => {}}
      onClose={handleClose}
      disableSwipeToOpen
    >
      <IconButton
        className="m-4 absolute top-0 right-0 z-999"
        onClick={handleClose}
        size="large"
      >
        <FuseSvgIcon color="action">heroicons-outline:x</FuseSvgIcon>
      </IconButton>

      {loading ? (
        <FuseLoading />
      ) : (
        <FuseScrollbars className="p-16">
          <div className="flex flex-col">
            <div className="flex justify-between items-end pt-60 mb-40 pl-8">
              <Typography className="text-3xl font-semibold leading-none">
                {tagId ? "Etiketi Düzenle" : "Yeni Etiket Ekle"}
              </Typography>
            </div>
            <div className="flex flex-col">
              <form className="mt-24" onSubmit={(e) => sendNewTag(e)}>
                <FormControl className="w-full">
                  <TextField
                    required
                    type="text"
                    variant="outlined"
                    label="Etiket Adı"
                    name="name"
                    value={tagName}
                    onChange={(e) => setTagName(e.target.value)}
                  />
                  <TextField
                    multiline
                    rows={4}
                    type="text"
                    variant="outlined"
                    label="Açıklama"
                    className="my-10"
                    value={tagDescription}
                    onChange={(e) => setTagDescription(e.target.value)}
                  />
                  <Button type="submit" variant="contained" color="primary">
                    Kaydet
                  </Button>
                  <ResponseMessage status={responseStatus} show={responseShow}></ResponseMessage>

                </FormControl>
              </form>
            </div>
          </div>
        </FuseScrollbars>
      )}
    </StyledSwipeableDrawer>
  );
}

export default withReducer("TagDrawer", reducer)(memo(TagDrawer));
