const FuseComponentsNavigation = {
  id: 'fuse-components',
  title: 'Fuse Components',
  type: 'collapse',
  icon: 'widgets',
  children: [
    {
      id: 'fuse-auth',
      title: 'FuseAuthorization',
      type: 'item',
      url: '/documentation/fuse-components/fuse-authorization',
    },
    {
      id: 'fuse-theme',
      title: 'FuseTheme',
      type: 'item',
      url: '/documentation/fuse-components/fuse-theme',
    },
    {
      id: 'fuse-layout',
      title: 'FuseLayout',
      type: 'item',
      url: '/documentation/fuse-components/fuse-layout',
    },
    {
      id: 'fuse-page-carded',
      title: 'FusePageCarded',
      type: 'item',
      url: '/documentation/fuse-components/fuse-page-carded',
    },
    {
      id: 'fuse-page-simple',
      title: 'FusePageSimple',
      type: 'item',
      url: '/documentation/fuse-components/fuse-page-simple',
    },
    {
      id: 'fuse-navigation',
      title: 'FuseNavigation',
      type: 'item',
      url: '/documentation/fuse-components/fuse-navigation',
    },
    {
      id: 'fuse-scrollbars',
      title: 'FuseScrollbars',
      type: 'item',
      url: '/documentation/fuse-components/fuse-scrollbars',
    },
    {
      id: 'fuse-highlight',
      title: 'FuseHighlight',
      type: 'item',
      url: '/documentation/fuse-components/fuse-highlight',
    },
    {
      id: 'fuse-countdown',
      title: 'FuseCountdown',
      type: 'item',
      url: '/documentation/fuse-components/fuse-countdown',
    },
    {
      id: 'fuse-message',
      title: 'FuseMessage',
      type: 'item',
      url: '/documentation/fuse-components/fuse-message',
    },
    {
      id: 'fuse-dialog',
      title: 'FuseDialog',
      type: 'item',
      url: '/documentation/fuse-components/fuse-dialog',
    },
  ],
};

export default FuseComponentsNavigation;
