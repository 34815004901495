import i18next from "i18next";
import DocumentationNavigation from "../main/documentation/DocumentationNavigation";

import ar from "./navigation-i18n/ar";
import en from "./navigation-i18n/en";
import tr from "./navigation-i18n/tr";
import authRoles from "../auth/authRoles";
import { useEffect } from "react";

i18next.addResourceBundle("en", "navigation", en);
i18next.addResourceBundle("tr", "navigation", tr);
i18next.addResourceBundle("ar", "navigation", ar);

// useEffect(async () => {
//   const response = await fetch(
//     "https://programatik-api.ingage.media/api/Actions?status=0&actionType=blacklist&pageIndex=1&pageSize=1"
//   );
//   const data = await response.json();
//   console.log(data, "SİDEBAR DATA");
// }, []);

const navigationConfig = [
  {
    id: "reklam",
    title: "Reklam",
    subtitle: "Reklam işlemleri",
    type: "group",
    icon: "heroicons-outline:cube",
    children: [
      {
        id: "reklamverenler",
        title: "Reklamverenler",
        type: "item",
        url: "advertisers",
        end: true,
      },
      {
        id: "oneriler",
        title: "Öneriler",
        type: "collapse",
        url: "actions/new",
        badge: {
          title: "27",
          classes: "px-8 bg-pink-600 text-white rounded-full mr-16",
        },
        children: [
          {
            id: "new-actions",
            title: "Yeni Öneriler",
            type: "item",
            url: "/actions/new",
            badge: {
              title: "27",
              classes: "px-8 bg-pink-600 text-white rounded-full",
            },
          },
          {
            id: "implemented-actions",
            title: "Uygulanan Öneriler",
            type: "item",
            url: "/actions/implemented",
          },
          {
            id: "planned-actions",
            title: "Planlanan Öneriler",
            type: "item",
            url: "/actions/planned",
          },
          {
            id: "ignored-actions",
            title: "Yoksayılan Öneriler",
            type: "item",
            url: "/actions/ignored",
          },
        ],
      },
      {
        id: "islem-gecmisi",
        title: "İşlem Geçmişi",
        type: "item",
        url: "activities",
      },
    ],
  },
  {
    id: "kullanici",
    title: "Kullanıcı",
    subtitle: "Kullanıcı işlemleri",
    type: "group",
    icon: "heroicons-outline:cube",
    children: [
      {
        id: "kullanicilar",
        title: "Kullanıcılar",
        type: "item",
        url: "users",
      },
    ],
  },
  {
    id: "system",
    title: "Sistem",
    subtitle: "Sistem işlemleri",
    type: "group",
    icon: "heroicons-outline:cube",
    children: [
      {
        id: "categories",
        title: "Kategoriler",
        type: "item",
        url: "categories",
      },
      {
        id: "tags",
        title: "Etiketler",
        type: "item",
        url: "tags",
      },
    ],
  },
];

export default navigationConfig;
