import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  toggle: false,
  item: null,
}
const stateSlice = createSlice({
  name: 'notificationPanel/state',
  initialState,
  reducers: {
    toggleNotificationPanel: (state, action) => state = { ...state, toggle: !state.toggle },
    sendItemToDrawer: (state, action) => state = { ...state, item: action.payload},
    openNotificationPanel: (state, action) => true,
    closeNotificationPanel: (state, action) => false,
  },
});

export const { toggleNotificationPanel, openNotificationPanel, closeNotificationPanel, sendItemToDrawer, getItemFromDrawer } =
  stateSlice.actions;

export const selectNotificationPanelState = ({ notificationPanel }) => notificationPanel.state;

export default stateSlice.reducer;
