import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as yup from "yup";
import _ from "@lodash";
import Paper from "@mui/material/Paper";
import jwtService from "src/app/auth/services/jwtService";
import { useEffect, useState } from "react";
import { Link } from "@mui/material";
import { useLocation } from "react-router-dom";

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  email: yup
    .string()
    .email("Lütfen geçerli bir mail adresi girin.")
    .required("Email adresi girin."),
  password: yup
    .string()
    .required("Lütfen parola girin.")
    .min(4, "Parola çok kısa - Minimum 8 karakter girin."),
});

const defaultValues = {
  email: "",
  password: "",
  remember: false,
};

function ClassicSignInPage() {
  document.title = "Giriş Yap - Ingage";
  const [errorMessage, setErrorMessage] = useState("");
  const location = useLocation();
  const isAuthorized =
    location.search.length > 0
      ? location.search.match(/authorized=(\d+)/)[1] == 1
        ? true
        : false
      : null;
  const token =
    location.search.length > 0 && isAuthorized
      ? location.search.match(/t=(.+?)(?=&|$)/)[1]
      : null;

  useEffect(() => {
    if (token !== null && isAuthorized !== null && isAuthorized !== false) {
      localStorage.setItem("jwt_access_token", token);
      jwtService.signInWithToken(token).then((user) => {
        // No need to do anything, user data will be set at app/auth/AuthContext
        window.location.href = "/";
      });
    }
  }, []);

  const [showErrors, setShowErrors] = useState(false);

  const { control, formState, handleSubmit, setValue } = useForm({
    mode: "onSubmit",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  function onSubmit({ email, password }) {
    setShowErrors(true); // Hataları göster
    if (isValid) {
      jwtService
        .signInWithEmailAndPassword(email, password)
        .then((user) => {
          // No need to do anything, user data will be set at app/auth/AuthContext
          window.location.href = "/";
        })
        .catch((error) => {
          setErrorMessage(error.message);
          console.log(error.message);
        });
    }
  }

  useEffect(() => {
    console.log(typeof errorMessage);
  }, [errorMessage]);

  if (formState.isLoading) {
    return null;
  }

  return (
    <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0">
      <Paper className="w-full sm:w-auto min-h-full sm:min-h-auto rounded-0 py-32 px-16 sm:p-48 sm:rounded-2xl sm:shadow">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <img className="w-48" src="/ingage-main-icon.png" alt="logo" />

          <Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight">
            Giriş Yap
          </Typography>

          <form
            name="loginForm"
            noValidate
            className="flex flex-col justify-center w-full mt-32"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="E-Posta"
                  autoFocus
                  type="email"
                  error={showErrors && !!errors.email} // Hataları gösterdikten sonra kontrol et
                  helperText={showErrors && errors?.email?.message} // Hataları gösterdikten sonra kontrol et
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />

            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-16"
                  label="Şifre"
                  type="password"
                  error={showErrors && !!errors.password} // Hataları gösterdikten sonra kontrol et
                  helperText={showErrors && errors?.password?.message} // Hataları gösterdikten sonra kontrol et
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />

            <Button
              variant="contained"
              color="secondary"
              className=" w-full mb-16"
              aria-label="Sign in"
              disabled={_.isEmpty(dirtyFields) || !isValid}
              type="submit"
              size="large"
            >
              Giriş
            </Button>
          </form>
          <div className="flex items-center mb-16">
            <div className="flex-auto mt-px border-t" />
            <Typography className="mx-8" color="text.secondary">
              ya da
            </Typography>
            <div className="flex-auto mt-px border-t" />
          </div>
          <Button
            variant="contained"
            color="primary"
            className=" w-full opacity-80"
          >
            <Link
              className="!text-white !no-underline"
              href={`https://programatik-api.ingage.media/saml/redirect?returnURL=${window.location.href}`}
            >
              SSO ile giriş yap
            </Link>
          </Button>
          {isAuthorized === false && token === null && (
            <p className="text-red-500 mt-8">Yetkisiz giriş.</p>
          )}
          {errorMessage && (
            <p className="text-red-500 mt-8 capitalize">{errorMessage}</p>
          )}
        </div>
      </Paper>
    </div>
  );
}

export default ClassicSignInPage;
