import FuseScrollbars from "@fuse/core/FuseScrollbars/FuseScrollbars";
import {
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Button,
  TableContainer,
  Paper,
} from "@mui/material";
import { useEffect, useState } from "react";
import FuseLoading from "@fuse/core/FuseLoading/FuseLoading";
import { useDispatch, useSelector } from "react-redux";
import { lighten } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import TableSortLabel from "@mui/material/TableSortLabel";
import jwtService from "../auth/services/jwtService";
import {
  sendItemToDrawer,
  toggleNotificationPanel,
} from "./storeCopy/stateSlice";
import TablePagination from "@mui/material/TablePagination";
import { selectUser } from "app/store/userSlice";
import { Navigate } from "react-router-dom";

// TODO: FuseScrollbars çalışmıyor. Sadece etiket tablosu içinde scroll istiyorum.
function TagsPage() {
  document.title = "Etiketler - Ingage";
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [tags, setTags] = useState([]);
  const [singleTag, setSingleTag] = useState([]);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState({ id: "name", direction: "asc" });

  const list = [
    {
      id: "tagId",
      align: "left",
      disablePadding: false,
      label: "ID",
      sort: true,
    },
    {
      id: "name",
      align: "left",
      disablePadding: false,
      label: "Etiket Adı",
      sort: true,
    },
  ];

  function handleRequestSort(property) {
    const id = property;
    let direction = "desc";

    if (order.id === property && order.direction === "desc") {
      direction = "asc";
    }
    setOrder({
      direction,
      id,
    });
  }

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const handleChangePage = async (event, newPage) => {
    await setPage(newPage);
  };

  const handleChangeRowsPerPage = async (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const getTags = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/IngageTags?pageIndex=${
          page + 1
        }&pageSize=${rowsPerPage}${
          order?.id ?? false
            ? `&SortOrderBy=${order.id}_${order.direction}`
            : ""
        }`,
        { headers: { Authorization: `Bearer ${jwtService.getAccessToken()}` } }
      );
      const data = await response.json();
      setTags(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  //   Çok fazla tag olursa sıkıntı. Geçici düzen.
  const sendDataToForm = (tagId) => {
    tags.items.forEach((tag) => {
      if (tag.tagId === tagId) {
        setSingleTag(tag);
      }
    });
  };

  useEffect(() => {
    getTags();
  }, [order, page, rowsPerPage]);

  const handleOpenDrawer = (tag) => {
    dispatch(toggleNotificationPanel());
    dispatch(
      sendItemToDrawer({
        type: "tag",
        tag,
        getTags,
      })
    );
  };
  const handleDelete = async (tag) =>{
    if (window.confirm(`${tag.name} silmek istediğinize emin misiniz?`)) {
      try {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/IngageTags?id=${tag.tagId}`,
            {
              headers: { Authorization: `Bearer ${jwtService.getAccessToken()}` },
              method: "DELETE"
            }
        );
        getTags();
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }

    }
  }
  if (!(user.roles.includes("SuperAdmin") || user.roles.includes("Admin"))) {
    return <Navigate to="404" />;
  }

  return (
    <div className="p-20 pb-0 ">
      <div className="max-w-max ml-auto mb-10">
        <Button variant="contained" color="primary" onClick={handleOpenDrawer}>
          Yeni Etiket Ekle
        </Button>
      </div>
      <div className="flex-1 ml-auto rounded-xl">
        <TableContainer component={Paper} className="max-h-[85vh] h-full">
          <Table stickyHeader>
            <TableHead className="bg-[#F6F9FB]">
              <TableRow className="h-48 sm:h-64">
                {list.map((row) => {
                  return (
                    <TableCell
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.mode === "light"
                            ? lighten(theme.palette.background.default, 0.4)
                            : lighten(theme.palette.background.default, 0.02),
                      }}
                      className="p-4 md:p-16 first:!pl-24"
                      key={row.id}
                      align={row.align}
                      padding={row.disablePadding ? "none" : "normal"}
                      sortDirection={
                        order.id === row.id ? order.direction : false
                      }
                    >
                      {row.sort && (
                        <Tooltip
                          title="Sort"
                          placement={
                            row.align === "right"
                              ? "bottom-end"
                              : "bottom-start"
                          }
                          enterDelay={300}
                        >
                          <TableSortLabel
                            active={order.id === row.id}
                            direction={order.direction}
                            className="font-semibold"
                            onClick={() => handleRequestSort(row.id)}
                          >
                            {row.label}
                          </TableSortLabel>
                        </Tooltip>
                      )}
                      {!row.sort && row.label}
                    </TableCell>
                  );
                }, this)}
                <TableCell className="p-4 md:p-16 font-700" />
              </TableRow>
            </TableHead>
            {loading ? (
              <FuseLoading />
            ) : (
              <TableBody>
                {tags?.items.map((tag) => (
                  <TableRow key={tag.tagId} className="bg-white">
                    <TableCell className="w-40">
                      <span>{tag.tagId}</span>
                    </TableCell>
                    <TableCell className="flex items-center justify-between">
                      <span>{tag.name}</span>
                    </TableCell>
                    <TableCell className="text-right">
                      <span
                        onClick={(e) => handleOpenDrawer(tag)}
                        className="hover:cursor-pointer hover:opacity-60 transition duration-300 ease-in-out mr-5"
                      >
                        Düzenle
                      </span>
                      <span
                          onClick={(e) => handleDelete(tag)}
                          className="hover:cursor-pointer hover:opacity-60 transition duration-300 ease-in-out"
                      >
                        Sil
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={tags?.totalItems ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={(a) => `${a.from} - ${a.to} Toplam ${a.count}`}
          labelRowsPerPage={"Sayfa başı kayıt:"}
        />
      </div>
    </div>
  );
}

export default TagsPage;
