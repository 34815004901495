import FuseScrollbars from "@fuse/core/FuseScrollbars";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import withReducer from "app/store/withReducer";
import { useSnackbar } from "notistack";
import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { useState } from "react";
import reducer from "./store";
import {
  closeNotificationPanel,
  selectNotificationPanelState,
  sendItemToDrawer,
  toggleNotificationPanel,
} from "./store/stateSlice";
import FuseLoading from "@fuse/core/FuseLoading/FuseLoading";

import ActionsDrawer from "src/app/Ingage/Drawer/ActionsDrawer";
import ActionsTestDrawer from "src/app/Ingage/Drawer/ActionsTestDrawer";
import AdvertisersDrawer from "src/app/Ingage/Drawer/AdvertisersDrawer";
import CampaignsDrawer from "src/app/Ingage/Drawer/CampaignsDrawer";
import OrdinoDrawer from "src/app/Ingage/Drawer/OrdinoDrawer";
import LineItemDrawer from "src/app/Ingage/Drawer/LineItemDrawer";
import CategoryDrawer from "src/app/Ingage/Drawer/CategoryDrawer";
import TagDrawer from "src/app/Ingage/Drawer/TagDrawer";
import UserDrawer from "src/app/Ingage/Drawer/UserDrawer";
import jwtService from "../../../auth/services/jwtService";

const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    backgroundColor: "white",
    width: "40%",
  },
}));

function NotificationPanel(props) {
  const dispatch = useDispatch();
  const state = useSelector(selectNotificationPanelState);
  const [loading, setLoading] = useState(false);
  const [listType, setListType] = useState("blacklist");

  // Toaster eklenecek.
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (state) {
      dispatch(closeNotificationPanel());
    }
  }, [location, dispatch]);

  function handleClose() {
    dispatch(closeNotificationPanel());
  }

  const [ingageTagsOptions, setIngageTagsOptions] = useState([]);
  const getIngageTagsOptions = async () => {
    try {
      setLoading(true);
      const response = await fetch(
          `${process.env.REACT_APP_API_URL}/IngageTags?pageIndex=1&pageSize=1000`,
        { headers: { Authorization: `Bearer ${jwtService.getAccessToken()}` } }
      );
      const data = await response.json();
      setIngageTagsOptions(data.items);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const [categoryOptions, setCategoryOptions] = useState([]);
  const getCategoryOptions = async () => {
    try {
      setLoading(true);
      const response = await fetch(
          `${process.env.REACT_APP_API_URL}/IngageCategories?pageIndex=1&pageSize=1000`,
        { headers: { Authorization: `Bearer ${jwtService.getAccessToken()}` } }
      );
      const data = await response.json();
      setCategoryOptions(data.items);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getIngageTagsOptions();
    getCategoryOptions();
  }, []);

  return (
    <StyledSwipeableDrawer
      open={state?.toggle || false}
      anchor="right"
      onOpen={(ev) => {}}
      onClose={(ev) => dispatch(toggleNotificationPanel())}
      disableSwipeToOpen
    >
      <IconButton
        className="m-4 absolute top-0 right-0 z-999"
        onClick={handleClose}
        size="large"
      >
        <FuseSvgIcon color="action">heroicons-outline:x</FuseSvgIcon>
      </IconButton>

      {loading ? (
        <FuseLoading />
      ) : (
        <div>
          {state?.item?.type === "advertiser" && <AdvertisersDrawer />}

          {state?.item?.type === "campaign" && <CampaignsDrawer />}
          {state?.item?.type === "ordino" && <OrdinoDrawer />}
          {state?.item?.type === "lineItem" && <LineItemDrawer />}
          {state?.item?.type === "suggestion" && (
            <ActionsDrawer actionType={listType} setAcitonType={setListType} />
          )}
          {state?.item?.type === "suggestion-test" && (
              <ActionsTestDrawer actionType={listType} setAcitonType={setListType} />
          )}
          {state?.item?.type === "category" && <CategoryDrawer />}
          {state?.item?.type === "tag" && <TagDrawer />}
          {state?.item?.type === "user" && <UserDrawer />}
        </div>
      )}
    </StyledSwipeableDrawer>
  );
}

export default withReducer(
  "notificationPanel",
  reducer
)(memo(NotificationPanel));
