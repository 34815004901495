import FuseSvgIcon from "@fuse/core/FuseSvgIcon/FuseSvgIcon";
import { useDispatch, useSelector } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Typography,
  Button,
  FormControl,
  TextField,
  Box,
  Tab,
  Tabs,
  Snackbar,
  Alert,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { motion } from "framer-motion";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import jwtService from "../auth/services/jwtService";
import FuseLoading from "@fuse/core/FuseLoading/FuseLoading";
import {
  sendItemToDrawer,
  toggleNotificationPanel,
} from "./storeCopy/stateSlice";
import moment from "moment";
import PropTypes from "prop-types";

import { selectUser } from "app/store/userSlice";
import { Navigate } from "react-router-dom";

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  margin: 0,
  border: "none!important",
  borderRadius: "8px!important",
  marginBottom: 24,
  "&:before": {
    display: "none",
  },
  "&:first-of-type": {},
  "&:last-of-type": {
    marginBottom: 0,
  },
}));

const ConfigurationPage = () => {
  document.title = "Konfigurasyon - Ingage";
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  const handleOpenDrawer = () => {
    dispatch(toggleNotificationPanel());
    dispatch(
      sendItemToDrawer({
        type: "user",
        getAdmins,
      })
    );
  };

  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState({});
  const [admins, setAdmins] = useState([]);
  const [users, setUsers] = useState([]);
  const [lastLoginDate, setLastLoginDate] = useState(null);

  const getStatus = async () => {
    try {
      setLoading(true);
      const res = await fetch(
          `${process.env.REACT_APP_API_URL}/Reports/status`,
        {
          headers: { Authorization: `Bearer ${jwtService.getAccessToken()}` },
        }
      );
      const data = await res.json();
      setStatus(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getAdmins = async () => {
    try {
      setLoading(true);
      const res = await fetch(
          `${process.env.REACT_APP_API_URL}/Users?Roles=SuperAdmin&Roles=Admin&PageSize=100`,
        {
          headers: { Authorization: `Bearer ${jwtService.getAccessToken()}` },
        }
      );
      const data = await res.json();
      setAdmins(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getUsers = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Users?PageSize=100`,
        { headers: { Authorization: `Bearer ${jwtService.getAccessToken()}` } }
      );
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getLastLoginDate = async () => {
    try {
      const req = await fetch(
        `${process.env.REACT_APP_API_URL}/Users/LastLoginDate`,
        {
          headers: { Authorization: `Bearer ${jwtService.getAccessToken()}` },
        }
      );
      const res = await req.json();
      setLastLoginDate(moment(res).format("DD.MM.YYYY HH:mm:ss"));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getData = async () => {
      await getStatus();
      await getAdmins();
      await getUsers();
      await getLastLoginDate();
      setLoading(false);
    };
    getData();
  }, []);

  // useEffect(() => {
  //   console.log(status);
  // }, [status]);

  if (!user.roles.includes("SuperAdmin")) {
    return <Navigate to="404" />;
  }

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div>
      <div className="flex w-full container">
        <div className="flex flex-col sm:flex-row flex-auto sm:items-center min-w-0 p-24 md:p-32 pb-0 md:pb-0 pl-10 md:pl-10">
          <div className="flex flex-col flex-auto">
            <Typography className="text-3xl font-semibold tracking-tight leading-8">
              Konfigürasyon Paneli
            </Typography>
            <Typography
              className="font-medium tracking-tight"
              color="text.secondary"
            >
              Sistem durumunu ve konfigürasyonları gözden geçirin.
            </Typography>
          </div>
        </div>
      </div>
      {/* Sistem Durumu */}
      <motion.div variants={item} className="container p-10 pb-24 pt-32">
        {/* <VisitorsOverviewWidget /> */}
        <div className="w-full flex items-center justify-between">
          <Typography className="text-lg font-semibold">
            Sistem Durumu
          </Typography>
        </div>
        <div className="w-full flex items-stretch justify-between gap-20 mt-20 flex-wrap">
          {/* Eğer sıkıntı var ise dinamik olarak bg-[#F44336] */}
          <div
              className={`py-20 flex-1 rounded-6 shadow-lg flex flex-col items-center justify-center gap-10 text-white min-w-[30%] ${
                  status.dv360Api.status === 0 ? "bg-[#4CAF4F]" : "bg-[#F44336]"
              }`}
          >
            <Typography className="text-3xl font-bold text-center">
              DV360 API
            </Typography>
            <Typography className="text-xl  leading-tight">
              {status.dv360Api.status === 0 ? "Stabil" : "Hata"}
            </Typography>
          </div>
          <div
              className={`py-20 flex-1 rounded-6 shadow-lg flex flex-col items-center justify-center gap-10 text-white min-w-[30%] ${
                  status.database.status === 0 ? "bg-[#4CAF4F]" : "bg-[#F44336]"
              }`}
          >
            <Typography className="text-3xl font-bold text-center">
              Veritabanı
            </Typography>
            <Typography className="text-xl  leading-tight">
              {status.database.status === 0 ? "Stabil" : "Hata"}
            </Typography>
          </div>
          <div
              className={`py-20 flex-1 rounded-6 shadow-lg flex flex-col items-center justify-center gap-10 text-white min-w-[30%] ${"bg-[#4CAF4F]"}`}
          >
            <Typography className="text-3xl font-bold text-center">
              Kullanıcı Arayüzü
            </Typography>
            <Typography className="text-xl  leading-tight">Stabil</Typography>
          </div>

          <div
              className={`py-20 flex-1 rounded-6 shadow-lg flex flex-col items-center justify-center gap-10 text-white min-w-[30%] ${
                  status.syncInsertionOrders.status === 0
                      ? "bg-[#4CAF4F]"
                      : "bg-[#F44336]"
              }`}
          >
            <Typography className="text-3xl font-bold text-center">
              DV360 Son Veri Çekme Tarihi
            </Typography>
            <Typography className="text-xl  leading-tight">
              {moment(status.syncInsertionOrders.timestamp).format(
                  "DD.MM.YYYY HH:mm:ss"
              )}
            </Typography>
          </div>
          <div
              className={`py-20 flex-1 rounded-6 shadow-lg flex flex-col items-center justify-center gap-10 text-white min-w-[30%] ${
                  status.dV360Action.status === 0 ? "bg-[#4CAF4F]" : "bg-[#F44336]"
              }`}
          >
            <Typography className="text-3xl font-bold text-center">
              DV360 Son Öneri Oluşma Tarihi
            </Typography>
            <Typography className="text-xl  leading-tight">
              {moment(status.dV360Action.timestamp).format(
                  "DD.MM.YYYY HH:mm:ss"
              )}
            </Typography>
          </div>
          <div
              className={`py-20 flex-1 rounded-6 shadow-lg flex flex-col items-center justify-center gap-10 text-white min-w-[30%] ${
                  status.googleAdsAction.status === 0 ? "bg-[#4CAF4F]" : "bg-[#F44336]"
              }`}
          >
            <Typography className="text-3xl font-bold text-center">
              Google Ads Son Öneri Oluşma Tarihi
            </Typography>
            <Typography className="text-xl  leading-tight">
              {moment(status.googleAdsAction.timestamp).format(
                  "DD.MM.YYYY HH:mm:ss"
              )}
            </Typography>
          </div>
          <div
              className={`py-20 flex-1 rounded-6 shadow-lg flex flex-col items-center justify-center gap-10 text-white min-w-[30%] ${
                  status.googleAnalyticsAction.status === 0 ? "bg-[#4CAF4F]" : "bg-[#F44336]"
              }`}
          >
            <Typography className="text-3xl font-bold text-center">
              Google Analytics Son Öneri Oluşma Tarihi
            </Typography>
            <Typography className="text-xl  leading-tight">
              {moment(status.googleAnalyticsAction.timestamp).format(
                  "DD.MM.YYYY HH:mm:ss"
              )}
            </Typography>
          </div>
          <div
              className={`py-20 flex-1 rounded-6 shadow-lg flex flex-col items-center justify-center gap-10 text-white min-w-[30%] ${
                  status.metaAdsAction.status === 0 ? "bg-[#4CAF4F]" : "bg-[#F44336]"
              }`}
          >
            <Typography className="text-3xl font-bold text-center">
              Meta Ads Son Öneri Oluşma Tarihi
            </Typography>
            <Typography className="text-xl  leading-tight">
              {status.metaAdsAction.timestamp ? moment(status.metaAdsAction.timestamp).format(
                  "DD.MM.YYYY HH:mm:ss"
              ) : ''}
            </Typography>
          </div>
          <div
              className={`py-20 flex-1 rounded-6 shadow-lg flex flex-col items-center justify-center gap-10 text-white min-w-[30%] bg-[#4CAF4F]`}
          >
            <Typography className="text-3xl font-bold text-center">
              Son Başarılı Giriş
            </Typography>
            <Typography className="text-xl  leading-tight">
              {lastLoginDate}
            </Typography>
          </div>
        </div>
      </motion.div>

      {/* SSO Konfigürasyonu */}
      <motion.div variants={item} className="container p-10">
        <StyledAccordion
            component={motion.div}
            variants={item}
            classes={{
              root: "FaqPage-panel shadow",
            }}
        >
          <AccordionSummary
              expandIcon={
                <FuseSvgIcon>heroicons-outline:chevron-down</FuseSvgIcon>
              }
          >
            <div className="flex items-center py-4">
              <FuseSvgIcon color="action">
                heroicons-outline:question-mark-circle
              </FuseSvgIcon>
              <Typography className="px-12 font-medium">
                SSO Konfigürasyonu
              </Typography>
            </div>
          </AccordionSummary>

          <AccordionDetails>
            <SSOConfiguration />
          </AccordionDetails>
        </StyledAccordion>
      </motion.div>

      {/* Admin Listesi */}
      <motion.div variants={item} className="container p-10">
        <StyledAccordion
          component={motion.div}
          variants={item}
          classes={{
            root: "FaqPage-panel shadow",
          }}
        >
          <AccordionSummary
            expandIcon={
              <FuseSvgIcon>heroicons-outline:chevron-down</FuseSvgIcon>
            }
          >
            <div className="flex items-center py-4">
              <FuseSvgIcon color="action">heroicons-outline:user</FuseSvgIcon>
              <Typography className="px-12 font-medium">
                Admin Listesi
              </Typography>
            </div>
          </AccordionSummary>

          <AccordionDetails className="flex flex-col gap-14">
            <Button
              variant="contained"
              color="secondary"
              className="max-w-max ml-auto"
              onClick={handleOpenDrawer}
            >
              Admin Ekle
            </Button>
            <AdminListTable admins={admins.items} setAdmins={setAdmins} />
          </AccordionDetails>
        </StyledAccordion>
      </motion.div>

      {/* Kullanıcı Listesi */}
      <motion.div variants={item} className="container p-10">
        <StyledAccordion
          component={motion.div}
          variants={item}
          classes={{
            root: "FaqPage-panel shadow",
          }}
        >
          <AccordionSummary
            expandIcon={
              <FuseSvgIcon>heroicons-outline:chevron-down</FuseSvgIcon>
            }
          >
            <div className="flex items-center py-4">
              <FuseSvgIcon color="action">
                heroicons-outline:users-group
              </FuseSvgIcon>
              <Typography className="px-12 font-medium">
                Kullanıcı Listesi
              </Typography>
            </div>
          </AccordionSummary>

          <AccordionDetails>
            <UserListTable users={users?.items} setUsers={setUsers} />
          </AccordionDetails>
        </StyledAccordion>
      </motion.div>

      {/* DV360 Konfigürasyonu */}
      <motion.div variants={item} className="container p-10">
        <StyledAccordion
          component={motion.div}
          variants={item}
          classes={{
            root: "FaqPage-panel shadow",
          }}
        >
          <AccordionSummary
            expandIcon={
              <FuseSvgIcon>heroicons-outline:chevron-down</FuseSvgIcon>
            }
          >
            <div className="flex items-center py-4">
              <FuseSvgIcon color="action">
                heroicons-outline:question-mark-circle
              </FuseSvgIcon>
              <Typography className="px-12 font-medium">
                DV360 Konfigürasyonu
              </Typography>
            </div>
          </AccordionSummary>

          <AccordionDetails>
            <DV360Configuration />
          </AccordionDetails>
        </StyledAccordion>
      </motion.div>
    </div>
  );
};

export default ConfigurationPage;

const AdminListTable = ({ admins, setAdmins }) => {
  const handleAdminStatusChange = async (id) => {
    try {
      const adminIndex = admins.findIndex((admin) => admin.id === id);
      const isActive = admins[adminIndex].isActive;

      if (isActive) {
        await fetch(
          `${process.env.REACT_APP_API_URL}/Users/${id}/DeActivate`,
          {
            method: "PUT",
            headers: { Authorization: `Bearer ${jwtService.getAccessToken()}` },
          }
        );
      } else {
        await fetch(
          `${process.env.REACT_APP_API_URL}/Users/${id}/Activate`,
          {
            method: "PUT",
            headers: { Authorization: `Bearer ${jwtService.getAccessToken()}` },
          }
        );
      }

      const updatedAdmins = [...admins];
      updatedAdmins[adminIndex].isActive = !isActive;
      setAdmins({ ...admins, items: updatedAdmins });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow className="border-b-2">
            <TableCell>İsim</TableCell>
            <TableCell>Mail</TableCell>
            <TableCell>Rol</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {admins.map((admin) => (
            <TableRow key={admin.id} className="border-b-2 last:border-b-0">
              <TableCell>
                {admin.firstName} {admin.lastName}
              </TableCell>
              <TableCell>{admin.email}</TableCell>
              <TableCell>{admin.roles[0]}</TableCell>
              <TableCell align="right">
                <Button
                  variant="contained"
                  color={admin.isActive ? "secondary" : "warning"}
                  size="small"
                  className="mx-4"
                  onClick={() => handleAdminStatusChange(admin.id)}
                >
                  {admin.isActive ? "Aktif" : "Pasif"}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const UserListTable = ({ users, setUsers }) => {
  const handleUserStatusChange = async (id) => {
    try {
      const userIndex = users.findIndex((user) => user.id === id);
      const isActive = users[userIndex].isActive;

      if (isActive) {
        await fetch(
          `${process.env.REACT_APP_API_URL}/Users/${id}/DeActivate`,
          {
            method: "PUT",
            headers: { Authorization: `Bearer ${jwtService.getAccessToken()}` },
          }
        );
      } else {
        await fetch(
          `${process.env.REACT_APP_API_URL}/Users/${id}/Activate`,
          {
            method: "PUT",
            headers: { Authorization: `Bearer ${jwtService.getAccessToken()}` },
          }
        );
      }

      const updatedUsers = [...users];
      updatedUsers[userIndex].isActive = !isActive;
      setUsers({ ...users, items: updatedUsers });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow className="border-b-2">
            <TableCell>İsim</TableCell>
            <TableCell>Mail</TableCell>
            <TableCell>Rol</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user.id} className="border-b-2 last:border-b-0">
              <TableCell>
                {user.firstName} {user.lastName}
              </TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>{user.roles[0]}</TableCell>
              <TableCell align="right">
                <Button
                  variant="contained"
                  color={user.isActive ? "secondary" : "warning"}
                  size="small"
                  className="mx-4"
                  onClick={() => handleUserStatusChange(user.id)}
                >
                  {user.isActive ? "Aktif" : "Pasif"}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const SSOConfiguration = () => {
  const [data, setData] = useState({
    ssoLoginURL: "",
    metaDataURL: "",
    acsURL: "",
    entityId: "",
    idpEntityId: "",
    certificateText: "",
  });

  const getSSOData = async () => {
    try {
      const req = await fetch(
        `${process.env.REACT_APP_API_URL}/Config/SSO`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${jwtService.getAccessToken()}` },
        }
      );
      const res = await req.json();
      setData(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSSOData();
  }, []);

  const handleConfigSave = async () => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/Config/SSO`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${jwtService.getAccessToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Default SSO Config
  //   {
  //     "id": 1,
  //     "entityId": "https://programatik-api.ingage.media/Saml2",
  //     "idpEntityId": "https://sts.windows.net/dd77b882-5df6-42ab-aff6-577da39a7ee7/",
  //     "metaDataURL": "https://login.microsoftonline.com/dd77b882-5df6-42ab-aff6-577da39a7ee7/federationmetadata/2007-06/federationmetadata.xml?appid=3fb4470d-219b-42c0-b411-ce446117a4f0",
  //     "ssoLoginURL": "https://login.microsoftonline.com/dd77b882-5df6-42ab-aff6-577da39a7ee7/saml2",
  //     "acsURL": "${process.env.REACT_APP_API_URL}/saml/login",
  //     "certificateText": "-----BEGIN CERTIFICATE-----\\nMIIC8DCCAdigAwIBAgIQG2a4FZ4Q77lLaBKG0WT8DzANBgkqhkiG9w0BAQsFADA0MTIwMAYDVQQD\\nEylNaWNyb3NvZnQgQXp1cmUgRmVkZXJhdGVkIFNTTyBDZXJ0aWZpY2F0ZTAeFw0yMzEyMDgxMDQ2\\nMTBaFw0yNjEyMDgxMDQ2MTJaMDQxMjAwBgNVBAMTKU1pY3Jvc29mdCBBenVyZSBGZWRlcmF0ZWQg\\nU1NPIENlcnRpZmljYXRlMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAqJ+7GPJOLSYd\\n9tLeFWHYU3m/rO17sX53Bt+TU7jhw22hvgzjzjgFszsiCkoXjaT6MacvMzNrS/Rkk9QsNNw7qol3\\nn9bZDF6m7xUhYIqlN5CFxqjmyeXT6bfrOnT2v8JDyR6B9xJZy03PdLv7e1LTZV1E3Zfw+wc0fyTP\\nelWsKCTKbazumLmAGYckz02E+4bDog9PJx+ebvRGm99dOHkk/o5SrUiFw4isE3WK5gm3WU+1QpZy\\njrM/S5Z49o1hnAXeOkHNislYPnHo/Jgv50UDNh5ucGvUg5fVt5r0H3wvKgIIDrA4NOnAWOtSxuCP\\nicGdL81vNk1edZ9pH0WhXoxfcQIDAQABMA0GCSqGSIb3DQEBCwUAA4IBAQADfgugD/mXng8iRCaW\\n0BkTdx3Uby1e/1isrfwWFq+JrPjMPjleqTFCkoPGmNjrKc7iyx/av22AxMnblG7lSKy+FvoKvSvr\\nOnuO1cBvhoaOJtxa/lLkYVe0MaIXs9/PL3TgofP3LhZbEj4H1eHsKaaQLoO3b8PI1rvOFapDBzvn\\nOclf+SfodXfo9I6EU6WDrRaGb1J5jL9TEZKrfJrd1Eiznw3p+weiGANRQQNnJA37TVHLWeb/6dC9\\nheINYY85UoUVvTPxpu3z10ieYLABHpwPyVei3hR0h/xEuly9/JTZm3eOE6nkgN8RQqD6m4VMSDKH\\nyRp/xUR4EuQHindDngBL\\n-----END CERTIFICATE-----"
  // }
  return (
    <form className="flex flex-col gap-14">
      <FormControl>
        <TextField
          id="ssoLoginURL"
          variant="outlined"
          label="Login URL"
          placeholder="https://programatik-api.ingage.media/saml/login"
          value={data.ssoLoginURL}
          onChange={(e) => setData({ ...data, ssoLoginURL: e.target.value })}
        />
      </FormControl>
      <FormControl>
        <TextField
          id="metaDataURL"
          variant="outlined"
          label="Metadata URL"
          placeholder="https://programatik-api.ingage.media/saml/login"
          value={data.metaDataURL}
          onChange={(e) => setData({ ...data, metaDataURL: e.target.value })}
        />
      </FormControl>
      <FormControl>
        <TextField
          id="acsURL"
          variant="outlined"
          label="ACS URL"
          placeholder="https://programatik-api.ingage.media/saml/login"
          value={data.acsURL}
          onChange={(e) => setData({ ...data, acsURL: e.target.value })}
        />
      </FormControl>
      <FormControl>
        <TextField
          id="entityId"
          variant="outlined"
          label="Entity ID"
          placeholder="https://programatik-api.ingage.media/saml/login"
          value={data.entityId}
          onChange={(e) => setData({ ...data, entityId: e.target.value })}
        />
      </FormControl>
      <FormControl>
        <TextField
          id="idpEntityId"
          variant="outlined"
          label="IDP Entity ID"
          placeholder="https://programatik-api.ingage.media/saml/login"
          value={data.idpEntityId}
          onChange={(e) => setData({ ...data, idpEntityId: e.target.value })}
        />
      </FormControl>
      <FormControl>
        <TextField
          id="certificateText"
          variant="outlined"
          label="Sertifika"
          multiline
          value={data.certificateText}
          onChange={(e) =>
            setData({ ...data, certificateText: e.target.value })
          }
        />
      </FormControl>
      <FormControl className="max-w-max">
        <Button
          variant="contained"
          color="secondary"
          className="rounded-4"
          onClick={handleConfigSave}
        >
          Kaydet
        </Button>
      </FormControl>
    </form>
  );
};

const DV360Configuration = () => {
  const [tab, setTab] = useState(2);
  const [values, setValues] = useState();
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState(false);

  const placeholder = `{
          "type": "service_account",
          "project_id": "example",
          "private_key_id": "",
          "private_key": "",
          "client_email": "examples@example.iam.gserviceaccount.com",
          "client_id": "",
          "auth_uri": "https://accounts.google.com/o/oauth2/auth",
          "token_uri": "https://oauth2.googleapis.com/token",
          "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
          "client_x509_cert_url": ""
}`;

  const getData = async () => {
    setLoading(true);
    const req = await fetch(
        `${process.env.REACT_APP_API_URL}/Config/DV`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${jwtService.getAccessToken()}`,
        },
      }
    );
    const data = await req.json();
    setValues(data);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const sendData = async () => {
    try {
      const dataToSend = {
        id: tab,
        serviceAccount: values.serviceAccount || "",
        apiKey: values.apiKey || "",
        oAuthClientId: values.oAuthClientId || "",
        oAuthClientSecret: values.oAuthClientSecret || "",
      };
      await fetch(`${process.env.REACT_APP_API_URL}/Config/DV`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${jwtService.getAccessToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend),
      });
      setSnackbar(true);
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) return <FuseLoading />;

  return (
    <Box sx={{ width: "100%" }}>
      <TextField
        id="service-account-key"
        variant="outlined"
        label="Service Account Key"
        placeholder={placeholder}
        fullWidth
        multiline
        minRows={8}
        maxRows={40}
        value={values?.serviceAccount || ""}
        InputLabelProps={{ shrink: true }}
        onChange={(e) =>
          setValues({ ...values, serviceAccount: e.target.value })
        }
      />
      <Button
        variant="contained"
        color="secondary"
        className="max-w-max rounded-4 mt-10"
        onClick={sendData}
      >
        Kaydet
      </Button>
      <Snackbar
        open={snackbar}
        autoHideDuration={4000}
        onClose={() => setSnackbar(false)}
        message="Kaydedildi"
        severity="success"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={() => setSnackbar(false)} severity="success">
          Kaydedildi
        </Alert>
      </Snackbar>
    </Box>
  );
};
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
